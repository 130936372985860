<template>
	<main class="vcc-main">
		<vcc-upcoming-webinar />
		<vcc-main-webinars />
		<illustrated-form type="ask2"/>
		<vcc-main-knowbase />
		<vcc-faq />
	</main>
</template>

<script>
import UpcomingWebinar from '@/components/main/UpcomingWebinar.vue'
import Webinars from '@/components/main/Webinars.vue'
import KnowledgeBase from '@/components/main/KnowledgeBase.vue'
import Illustrated from '@/components/forms/Illustrated.vue'
import Faq from '@/components/FaqSection.vue'
import { mapActions } from 'vuex'
export default {
	components: {
		'vcc-upcoming-webinar': UpcomingWebinar,
		'vcc-main-webinars': Webinars,
		'vcc-main-knowbase': KnowledgeBase,
		'illustrated-form': Illustrated,
		'vcc-faq': Faq,
	},
	methods: mapActions(['fetchAllWebinars', 'fetchAllWebinarsFromWebinarRu']),
	created() {
		this.fetchAllWebinars()
		this.fetchAllWebinarsFromWebinarRu()
	}
}
</script>
