<template>
	<div :class="['webinar-card', { 'wc__nobtns': no_btns, 'wc__past': webinar_data.is_ended || is_past, 'wc__planned': !webinar_data.is_ended, 'wc__on-air': is_onair, 'webinar-card__two-rows': rows===2, 'wc__fake': is_fake}]" aria-label="Карточка вебинара">
		<div class="wc--header">
			<p class="wc--datetime" v-if="is_fake">Время и дата проведения уточняются</p>
			<p class="wc--datetime" v-else-if="is_onair">Сейчас в эфире</p>
			<time class="wc--datetime" :datetime="webinar_data? webinar_data.started : '00:00'" v-else>
				<span>{{ webinar_data? normalizeTime(webinar_data.started) : '00:00' }}</span>
				<span>{{ webinar_data? normalizeDate(webinar_data.started) : '00:00' }}</span>
			</time>
		</div>
		<div class="wc--body">
			<div class="wc--heading">
				<h3><router-link :to="{ name: 'Webinar', params: { id: webinar_data.id ? webinar_data.id : 0 } }">{{ webinar_data.title ? webinar_data.title : 'Название вебинара'}}</router-link></h3>
				<hr v-if="false">
			</div>
			<div class="wc--main" v-if="false">
				<dl class="wc--speakers dl__theme" aria-label="Список спикеров">
					<div class="speakers--description">
						<dt class="hl__blue" v-if="!is_fake">{{ webinar_data.organization ? webinar_data.organization : 'Организация не указана' }}</dt>
						<dd>{{ webinar_data.speakers ? webinar_data.speakers : 'Спикеры не определены' }}</dd>
					</div>
				</dl>
			</div>
			<div class="wc--footer" v-if="!no_btns && is_onair">
				<router-link :to="{ name: 'SubscribeToWebinar', params: { id: webinar_data.id} }" class="link__btn btn__blue">Подключиться</router-link>
			</div>
			<div class="wc--footer" v-else-if="!no_btns && webinar_data.is_ended">
				<a :href="webinar_data.materials_link" target="__blank" class="link__btn btn__white">Материалы</a>
				<a :href="webinar_data.record_link" target="__blank" class="link__btn btn__blue">Смотреть в записи</a>
			</div>
			<div class="wc--footer" v-else-if="!no_btns">
				<router-link :to="{ name: 'Webinar', params: { id: webinar_data.id } }" class="link__btn btn__white">Подробнее</router-link>
				<router-link :to="{ name: 'SubscribeToWebinar', params: { id: webinar_data.id} }" class="link__btn btn__blue">Записаться</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		'no_btns',
		'is_past',
		'is_planned',
		'is_onair',
		'rows',
		'webinar_data',
		'is_fake'
	]
}
</script>
