<template>
	<section class="section-faq">
		<h2 :class="['heading', 'heading__hr', { 'subheading': subheading }]">Популярные вопросы</h2>
		<div class="faq--questions-wrapper">
			<ol class="faq-wrapper">
				<li v-for="question, key in popularQuestions" :key="'popular-question-'+key">
					<router-link :to="{ name: 'Question', params: { question: question.id } }" class="link__btn btn__blue link__faq">{{ question.title }}</router-link>
				</li>
				<li><router-link :to="{ name: 'FaqExperts' }" class="link__btn btn__white">Ко всем вопросам</router-link></li>
			</ol>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	props: ['subheading'],
	computed: {
		...mapGetters(['getQuestions']),
		popularQuestions() {
			const questions = Array.from(this.getQuestions)
			if(questions.length > 0) {
				return questions.filter((question) => {
					if(question.id == 84 || question.id == 82 || question.id == 91) {
						return question
					}
				})
			} else {
				return []
			}
		}
	},
	methods: {
		...mapActions(['fetchAllQuestions'])
	},
	created() {
		this.fetchAllQuestions()
	}
}
</script>
