<template>
	<section class="main--webinars">
		<h2 class="heading heading__hr">Вебинары</h2>
		<div class="mw--wrapper">
			<div class="mw--column mw--column__left">
				<h3 class="mw--heading">Прошедшие</h3>
				<div class="mw--cards-wrapper">
					<webinar-card :no_btns="true" :is_past="true" v-for="webinar, key in getWebinars.slice(0, 2)" :key="'webinar-'+key" :webinar_data="webinar"/>
				</div>
				<router-link :to="{ name: 'WebinarsArchive' }" class="link__btn btn__white">В архив</router-link>
			</div>
			<div class="mw--column mw--column__right">
				<h3 class="mw--heading">Предстоящие</h3>
				<div class="mw--cards-wrapper" v-if="getActiveWebinars.length == 0">
					<webinar-card :no_btns="true" :is_fake="true" :webinar_data="getFakeWebinar"/>
					<webinar-card :no_btns="true" :is_fake="true" :webinar_data="getFakeWebinar"/>
				</div>
				<div class="mw--cards-wrapper" v-else-if="getActiveWebinars.length == 1">
					<webinar-card :no_btns="true" :is_past="false" v-for="webinar, key in getActiveWebinars.slice(0, 1)" :key="'webinar-'+key" :webinar_data="webinar"/>
					<webinar-card :no_btns="true" :is_fake="true" :webinar_data="getFakeWebinar"/>
				</div>
				<div class="mw--cards-wrapper" v-else-if="getActiveWebinars.length > 1">
					<webinar-card :no_btns="true" :is_past="false" v-for="webinar, key in getActiveWebinars.slice(0, 2)" :key="'webinar-'+key" :webinar_data="webinar"/>
				</div>
				<router-link :to="{ name: 'Webinars' }" class="link__btn btn__blue">В календарь</router-link>
			</div>
		</div>
	</section>
</template>

<script>
import Webinar from '@/components/cards/Webinar.vue'
import { mapGetters } from 'vuex'
export default {
	components: {
		'webinar-card': Webinar,
	},
	computed: {
		...mapGetters(['getActiveWebinars', 'getFakeWebinar', 'getWebinars']),
	},
}
</script>
