<template>
	<section class="illustration-block ib-subscribe ib-subscribe-v1 illustration-block__bgicon" v-if="type==='subs1'">
			<div class="ib--icon-block ib--bg__icon"></div>
			<transition name="fade-in">
				<div class="ib--main" v-if="delivered === 'idle'">
					<article class="ib--article">
						<h4 class="ib--heading">Подписаться на обновления расписания вебинаров</h4>
						<p class="ib--text">Получайте уведомления об изменениях в расписании предстоящих вебинаров, а также добавлении записей прошедших мероприятий в архив.</p>
					</article>
					<form @submit.prevent="sendEmail('webinars')">
						<div class="form-row form-row__ib">
							<div class="input-wrapper">
								<input v-model="user_data.email" type="email" name="email" id="email" class="input input__white" placeholder="Ваша электронная почта...">
								<span class="validity-icon"></span>
							</div>
							<button type="submit" class="btn__white btn__white-border">Подписаться</button>
						</div>
					</form>
				</div>
				<div class="ib--main ib--main__form-state" v-else-if="delivered === 'success'">
					<article class="ib--article">
						<h4 class="ib--heading">Получилось!</h4>
						<p class="ib--text">Теперь вы будете получать уведомления об изменениях в расписании предстоящих вебинаров, а также добавлении записей прошедших мероприятий в архив.  </p>
					</article>
				</div>
				<div class="ib--main ib--main__form-state" v-else-if="delivered === 'fail'">
					<article class="ib--article">
						<h4 class="ib--heading">Не получилось...</h4>
						<p class="ib--text">Что-то пошло не так, и мы уже работаем над этим. Попробуйте еще раз через какое-то время.</p>
					</article>
				</div>
				<div class="ib--main ib--main__form-state" v-else-if="delivered === 'conflict'">
					<article class="ib--article">
						<h4 class="ib--heading">Не получилось...</h4>
						<p class="ib--text">Вы уже подписаны на уведомления об изменениях в расписании предстоящих вебинаров.</p>
					</article>
				</div>
			</transition>
		</section>

		<section class="illustration-block ib-subscribe ib-subscribe-v2 illustration-block__bgicon" v-else-if="type==='subs2'">
			<div class="ib--icon-block ib--bg__icon"></div>
			<transition name="fade-in">
				<div class="ib--main" v-if="delivered === 'idle'">
					<article class="ib--article">
						<h4 class="ib--heading">Подписаться на обновления Базы знаний</h4>
						<p class="ib--text">Получайте уведомления о появлении новых документов, методических указаний и других материалов в нашей Базе знаний!</p>
					</article>
					<form @submit.prevent="sendEmail('only_knowlage_base')">
						<div class="form-row form-row__ib">
							<div class="input-wrapper">
								<input v-model="user_data.email" type="email" name="email" id="email" class="input input__white" placeholder="Ваша электронная почта...">
								<span class="validity-icon"></span>
							</div>
							<button type="submit" class="btn__white btn__white-border">Подписаться</button>
						</div>
					</form>
				</div>
				<div class="ib--main ib--main__form-state" v-else-if="delivered === 'success'">
					<article class="ib--article">
						<h4 class="ib--heading">Получилось!</h4>
						<p class="ib--text">Теперь вы будете получать уведомления о появлении новых материалов нашей Базы знаний на свой почтовый ящик. </p>
					</article>
				</div>
				<div class="ib--main ib--main__form-state" v-else-if="delivered === 'fail'">
					<article class="ib--article">
						<h4 class="ib--heading">Не получилось...</h4>
						<p class="ib--text">Что-то пошло не так, и мы уже работаем над этим. Попробуйте еще раз через какое-то время.</p>
					</article>
				</div>
				<div class="ib--main ib--main__form-state" v-else-if="delivered === 'conflict'">
					<article class="ib--article">
						<h4 class="ib--heading">Не получилось...</h4>
						<p class="ib--text">Вы уже подписаны на уведомления о появлении новых материалов нашей Базы знаний.</p>
					</article>
				</div>
			</transition>
		</section>

		<section class="illustration-block ib-subscribe ib-ask-expert-v1 illustration-block__bgicon" v-else-if="type==='ask1'">
			<div class="ib--icon-block ib--bg__icon"></div>
			<div class="ib--main">
				<article class="ib--article">
					<h4 class="ib--heading">Задай свой вопрос эксперту!</h4>
					<p class="ib--text">На нашем портале Вы можете связаться напрямую с экспертами дорожного хозяйства и задать им свой вопрос. </p>
				</article>
				<div class="form-row form-row__ib">
					<router-link :to="{ name: 'AskQuestionExpert' }" class="link__btn btn__white btn__white-border">Задать вопрос</router-link>
				</div>
			</div>
		</section>

		<section class="illustration-block ib-subscribe ib-ask-expert-v2 illustration-block__bgicon" v-else-if="type==='ask2'">
			<div class="ib--icon-block ib--bg__icon"></div>
			<div class="ib--main">
				<article class="ib--article">
					<h4 class="ib--heading">Задай свой вопрос эксперту!</h4>
					<p class="ib--text">На нашем портале Вы можете связаться напрямую с экспертами дорожного хозяйства и задать им свой вопрос.</p>
					<p class="ib--text">Ответ будет опубликован на нашем сайте. Кстати, там же вы можете посмотреть ответы на вопросы других пользователей.</p>
				</article>
				<div class="form-row">
					<router-link :to="{ name: 'FaqExperts' }" class="link__btn btn__blue btn__transparent-bg btn__white-border">К другим ответам</router-link>
					<router-link :to="{ name: 'AskQuestionExpert' }" class="link__btn btn__white btn__white-border">Задать вопрос</router-link>
				</div>
			</div>
		</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	data() {
		return {
			user_data: {
				email: null,
				subscription_type: null
			}
		}
	},
	props: ['type'],
	computed: {
		...mapGetters(['getStatus']),
		delivered() {
			let status = null
			if(this.getStatus != null) {
				switch (this.getStatus) {
					case 200:
						status = 'success'
						break;
					case 409:
						status = 'conflict'
						break;
					default:
						status = 'fail'
						break;
				}
				return status
			} else {
				status = 'idle'
				return status
			}
		}
	},
	methods: {
		...mapActions(['subscribeUser', 'cleanStatusCode']),
		sendEmail(type) {
			this.user_data.subscription_type = type
			this.subscribeUser(this.user_data)
		}
	},
	beforeDestroy() {
		this.cleanStatusCode()
	}
}
</script>

<style>

</style>
