<template>
	<section class="upcoming-webinar">
		<h2 class="heading">Ближайший вебинар</h2>
		<webinar-card :is_onair="true" :webinar_data="startedWebinar" v-if="startedWebinar && !animation"/>
		<webinar-card :is_onair="false" :webinar_data="activeWebinar" v-else-if="activeWebinar && !animation"/>
		<div class="main__loading" v-else>
			<transition name="fade-in">
				<book/>
			</transition>
		</div>
	</section>
</template>

<script>
import Webinar from '@/components/cards/Webinar.vue'
import { mapActions, mapGetters } from 'vuex'
import book from '@/components/animations/book.vue';
export default {
	data: () => ({
		animation: false,
	}),
	components: {
		'webinar-card': Webinar,
		book,
	},
	computed:{
		...mapGetters(['getWebinars', 'getActiveWebinars', 'getStartedWebinar']),
		startedWebinar() {
			return this.getStartedWebinar ? this.getStartedWebinar : false
		},
		activeWebinar() {
			return this.getActiveWebinars ? this.getActiveWebinars[0] ? this.getActiveWebinars[0] : false  : false
		},
	},
	methods: {
		...mapActions(['fetchStartedWebinars']),
		toggleAnimation() {
			return this.animation ? this.animation = false : this.animation = true
		},
	},
	created() {
		this.fetchStartedWebinars()
		this.toggleAnimation()
		setTimeout(this.toggleAnimation, 800)
	},
}
</script>
<style scoped>
	.main__loading {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 20vh;
		width: 100%;
	}
</style>
